@import "@palette";

.root {
    background: $color-common-background;
}
.main {
    margin-top: -30px;
    padding: 26px 55px;
    background: #ffffff;
    border-radius: 4px;
}
.over {
    height: 30px;
    width: 100%;
    background-color: $color-common-background;
}

.arrow {
    path {
        fill: $color-gray-3;
    }
}

@media (max-width: 600px) {
    .main {
        padding: 13px 12px;
    }

    .GlobalWidth {
        padding: 0;
    }
    .addToList {
        display: none;
    }
    
    .container {
        composes: globalWidth from global;
        background: #FFF;
        margin-top: -30px;
        border-radius: 10px;

        padding: 0;
    }
}
