@import "@palette";
.root{
	height: 429px;
	
	border-radius: 4px;
	
	&--blur{
		composes: root;
		filter: blur(3px);
	}
	width: 100%;
	padding: 24px 10px 10px 10px;
	@media (min-width: 601px){
		padding: 24px;
		width: 340px;
		margin: 14px 0 0 40px;
		border: 1px solid $color-gray-6;
	}
}
.title{
	width: 267px;
	height: 26px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;

	color: $color-gray-4;
	margin: 0;
}
.avatar{
	img{
		border-radius: 20px;
	}
}
.text{
	height: 20px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $color-gray-1;
	margin: 0;
}
.bar {
	margin-top: 8px;
	height: 12px;
	border-radius: 6px;
	background: $color-gray-7;
	&--filled{
		composes: bar;
		margin: 0;
		background: $color-violet-1;
	}
}